import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public appPages = [
    {
      title: 'ICE Unlock',
      url: '/privacy-policy',
      src: '/assets/images/ice-unlock.png'
    }
  ];

  constructor(
    public translate: TranslateService
  ) {
    document.body.classList.toggle('dark', true);
  }

  ngOnInit() {
    this.initializeTranslateServiceConfig();
  }

  initializeTranslateServiceConfig() {
    let userLang = navigator.language.split('-')[0];
    userLang = /(en|es)/gi.test(userLang) ? userLang : 'en';
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(userLang);
  }
}
